import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
import YouTube from '../components/YouTube';
import Action from '../components/Action';
export const _frontmatter = {
  "title": "Indie Hackers Podcast",
  "date": "2021-02-22",
  "promo": "grids",
  "description": "Mastery Games on Indie Hackers!",
  "color": "#5d97e5"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Something awesome just happened. I was invited on to the amazing `}<a parentName="p" {...{
        "href": "https://www.indiehackers.com/podcasts"
      }}>{`Indie Hackers podcast`}</a>{`. This is one of the few podcasts that I subscribe to. I like listening to it right before work, putting me in the entrepreneur headspace. It's inspiring to hear the stories of people pursuing their passions and `}<a parentName="p" {...{
        "href": "/post/overcoming-fear-and-doing-your-own-thing/"
      }}>{`doing their own thing`}</a>{`.`}</p>
    <p>{`Sometimes as a solo entrepreneur it can feel like you're in it alone. It can be rough at times. But I've found that podcasts like Indie Hackers really help you remember that you're part of a passionate group of dream-chasing people. So it was a huge honor to be on the show and share my story.`}</p>
    <p>{`I had such a blast chatting with `}<a parentName="p" {...{
        "href": "https://twitter.com/csallen"
      }}>{`Courtland`}</a>{` on this episode. Some highlights:`}</p>
    <p>{`🐚 How watching Moana inspired me to quit my job`}<br parentName="p"></br>{`
`}{`🥶 Avoiding feeling paralyzed about finding the perfect plan`}<br parentName="p"></br>{`
`}{`🎃 A lesson from selling pumpkins as a kid`}<br parentName="p"></br>{`
`}{`👾 How I started Mastery Games®`}<br parentName="p"></br>{`
`}{`🚀 What I used to learn marketing skills as an engineer`}<br parentName="p"></br>{`
`}{`⛏️ How I dug myself out of a rut of discouragement`}<br parentName="p"></br>{`
`}{`🌟 The importance of building `}<em parentName="p">{`remarkability`}</em>{` into your product`}</p>
    <p>{`Here's `}<a parentName="p" {...{
        "href": "https://share.transistor.fm/s/a039c5a2"
      }}>{`the episode`}</a>{`, hope you enjoy!`}</p>
    <Action link="https://share.transistor.fm/s/a039c5a2" mdxType="Action">Listen now</Action>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      